<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333">{{ $t("List of resources") }}</p>
      </div>

      <div class="table_style">
        <el-table ref="multipleTable" header-align="center" border class="main-table" :data="tableData"
          tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="imagesName" :label='$t("Name")' align="center" />
          <el-table-column :label='$t("Picture")' align="center">
            <template slot-scope="scope">
              <el-image :src="scope.row.imagesSrc" fit="fill" style="width: 100px; height: 100px"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="fileName" :label='$t("File")' align="center" />
          <el-table-column :label='$t("Operation")' align="center">
            <template slot-scope="scope">
              <el-button type="primary" style="background-color: #218da0;" @click="handleDownFile(scope.row)">{{
                  $t("Resource Download")
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="footer">
        <div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageData.page" :page-size="pageData.pagesize" layout="total,  prev, pager, next, jumper"
            :total="pageData.total" background>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tableData: [],
      pageData: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.handleGetData()
  },
  methods: {
    /* 下载文件 */
    handleDownFile(row) {
      window.location.href = row.filesrc
    },
    /* 获取数据 */
    handleGetData() {
      let params = {
        page: this.pageData.page,
        pagesize: this.pageData.pagesize,
      };
      this.$axios.get('/w1/display/category/imagesfilesrc', { params: params }).then(res => {
        const { data } = res.data
        this.tableData = data.data
        if (this.tableData.length > 0) {
          for (let index = 0; index < this.tableData.length; index++) {
            this.tableData[index].fileName = ''
            if (this.tableData[index].filesrc != '') {
              let srcArr = this.tableData[index].filesrc.split("?")[0].split("/")
              this.tableData[index].fileName = srcArr[srcArr.length - 1]
            }
          }
        }
        this.pageData.total = data.total
      })
    },
    handleSizeChange(val) {
      this.pageData.pagesize = val;
      this.handleGetData();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.handleGetData();
    },
  },
};
</script>
<style lang="scss" scoped>
.rightSide {
  width: 1320px;
  //height: 786px;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  &_inner {
    width: 1218px;
    //height: 714px;

    &_title {
      width: 1218px;
      height: 68px;
    }
  }
}

.table_style {
  width: 1218px;
  margin-top: 30px;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}
</style>
